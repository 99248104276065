<template>
  <v-content color="primary">
    <v-container fluid id="main-container" grid-list-md>
      <v-snackbar v-model="snackbar" :timeout="timeout" top>
        {{ t(text) }}
        <v-btn flat @click="snackbar = false">
          {{ t('Close') }}
        </v-btn>
      </v-snackbar>

      <v-layout row wrap justify-center class="d-inline-block w-100">
        <v-card>
          <v-card-title>
            <span class="menu-icon bigger material-symbols-outlined">
              person_check
            </span>
            <span class="table-title">{{ t('Reseller activation') }}</span>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" :label="t('Search')" single-line
              hide-details></v-text-field>
          </v-card-title>
          <v-data-table :pagination.sync="pagination" :headers="headers" :items="subscribers" class="elevation-1"
            :loading="loading" :search="search" :total-items="10" hide-actions>
            <template slot="items" slot-scope="props">
              <td class="text-xs-left no-wrap">{{ props.item.username }}
              </td>
              <td class="text-xs-left no-wrap">{{ props.item.email }}</td>
              <td class="text-xs-left">
                <v-tooltip bottom v-if="$store.getters.isAdmin || $store.getters.isOwner">
                  <template v-slot:activator="{ on }">
                    <v-select :items="types" v-model="props.item.type_user">
                    </v-select>
                  </template>
                </v-tooltip>
              </td>
              <td class="text-xs-left">
                <v-tooltip bottom v-if="$store.getters.isAdmin || $store.getters.isOwner">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="confirmReseller(props.item.pk, props.item.type_user)"
                      color="success darken-1" icon ripple small dark>
                      <v-icon small>add</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ ('Add') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="deleteSubscriber(props.item.pk)" color="error" small icon ripple dark>
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ t('Delete') }}</span>
                </v-tooltip>
              </td>
            </template>
            <template slot="no-data">
              <v-subheader>{{ t('No data available') }}</v-subheader>
            </template>
          </v-data-table>
          <div
            style="display: flex; flex-direction: row; justify-content: center; align-items: center; flex-wrap: wrap;">
            <v-pagination v-model="pagination.current" :length="pagination.total" :total-visible="6"></v-pagination>
            <v-select style="margin-left: 10px; max-width: 50px" :items="[10, 25, 50]" v-model="page_size">
            </v-select>
          </div>
        </v-card>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import axios from 'axios';
import { debounce } from 'vue-debounce';

export default {
  name: "ResellersActivation",
  data() {
    return {
      pagination: {
        current: 1,
        total: 20,
        sortBy: undefined,
        descending: undefined
      },
      page_size: 10,
      dialog: false,
      headers: [
        {
          text: this.$translate.locale['Username'],
          align: 'left',
          value: 'username',
        },
        { text: this.$translate.locale['Email'], value: 'email' },
        { text: this.$translate.locale['User type'], value: 'type_user', sortable: false },
        { text: this.$translate.locale['Action'], value: 'action', sortable: false },
      ],
      types: [
        { value: 'A', text: 'Administrator' },
        { value: 'M', text: 'Manager' },
        { value: 'R', text: 'Reseller' },
      ],
      subscribers: [],
      loading: true,
      snackbar: false,
      search: '',
      text: 'Oops... Something went wrong',
      timeout: 5000,
      rules: {
        required: value => !!value || this.$translate.locale['Required.'],
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$translate.locale['Invalid e-mail.']
        },
      },
      getDataDebounced: debounce((...args) => this.getData(...args), '300ms'),
    }
  },
  methods: {
    getData(resetPage = false) {
      let url = `/api/resellersTable/`;

      const params = {
        page: this.pagination.current,
        page_size: this.page_size,
        sort_by: this.pagination.descending ? `-${this.pagination.sortBy}` : this.pagination.sortBy,
      };

      if (this.search) {
        params.search = this.search;

        if (this.pagination.current !== 1 && resetPage) {
          this.pagination.current = 1;
          // return, because changing `this.pagination.current = 1`
          // will call this.getData() once more
          return;
        }
      }

      axios.get(url, { params })
        .then((response) => {
          if (response.status === 200) {
            this.subscribers = response.data.results;
            this.pagination.total = response.data.total_pages;
            this.loading = false;
          }
        }).catch((error) => {
          this.text = "Connection error";
          this.snackbar = true;
        });
    },
    deleteSubscriber(pk) {
      axios.delete(`/api/resellers/delete/${pk}/`)
        .then((response) => {
          if (response.status === 204) {
            this.text = "Successful";
            this.snackbar = true;
            this.getData();
          }
        }).catch((error) => {
          this.text = "Connection error";
          this.snackbar = true;
        });
    },
    confirmReseller(pk, role) {
      axios.put(`/api/resellersConfirm/${pk}/`, { type_user: role })
        .then((response) => {
          if (response.status === 200) {
            this.text = "Successful";
            this.getData();
            this.snackbar = true;
          }
        }).catch((error) => {
          this.text = "Connection error";
          this.snackbar = true;
        });
    },
  },
  beforeCreate() {
    if (!this.$store.getters.loggedIn) {
      this.$router.push('/')
    }
    if (!this.$store.getters.isAdmin && !this.$store.getters.isOwner) {
      this.$router.push('/cards')
    }
  },
  mounted() {
    this.getData();
  },
  watch: {
    'pagination.current': function () { this.getDataDebounced(); },
    'page_size': function (newValue, oldValue) { this.getDataDebounced(newValue !== oldValue); },
    'pagination.sortBy': function () { this.getDataDebounced(); },
    'pagination.descending': function () { this.getDataDebounced(); },
    'search': function (newValue, oldValue) { this.getDataDebounced(newValue !== oldValue); },
  }
}
</script>

<style scoped></style>
